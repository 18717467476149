// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-seo-post-js": () => import("/opt/build/repo/www.lastingclean.co.nz/src/templates/seo-post.js" /* webpackChunkName: "component---src-templates-seo-post-js" */),
  "component---src-templates-post-js": () => import("/opt/build/repo/www.lastingclean.co.nz/src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/www.lastingclean.co.nz/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-lp-decking-treatment-js": () => import("/opt/build/repo/www.lastingclean.co.nz/src/pages/lp-decking-treatment.js" /* webpackChunkName: "component---src-pages-lp-decking-treatment-js" */),
  "component---src-pages-old-index-js": () => import("/opt/build/repo/www.lastingclean.co.nz/src/pages/old-index.js" /* webpackChunkName: "component---src-pages-old-index-js" */),
  "component---src-pages-page-2-js": () => import("/opt/build/repo/www.lastingclean.co.nz/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/www.lastingclean.co.nz/.cache/data.json")

