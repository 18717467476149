module.exports = [{
      plugin: require('/opt/build/repo/www.lastingclean.co.nz/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-121330352-1"},
    },{
      plugin: require('/opt/build/repo/www.lastingclean.co.nz/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography.js"},
    },{
      plugin: require('/opt/build/repo/www.lastingclean.co.nz/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":600,"linkImagesToOriginal":false,"showCaptions":true,"backgroundColor":"transparent"},
    },{
      plugin: require('/opt/build/repo/www.lastingclean.co.nz/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
